/*  color variables */
:root {
  --background: #ffffff;
  --text-primary: #000;
  --text-secondary: #8e05c2;
  --accent: #8e05c2;
  --border: #333;
  --form-bg: 1#fff;
}

[data-theme='dark'] {
  --background: #000;
  --text-primary: #fff;
  --text-secondary: #8e05c2;
  --accent: #8e05c2;
  --border: #808080;
  --form-bg: #191919;
}
input {
  height: 20px;
  width: 50%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  margin: 20px;
}

::placeholder {
  font-size: 20px;
}

:focus {
  outline: none;
}

.App {
  text-align: center;
  background: url(./assets/picture/bg.jpeg);
  background-size: cover;
  background-position: 0%;
  height: 100vh;
  color: white;
}

.header {
  line-height: 40px;
  background: transparent;
  backdrop-filter: blur(50px);
  padding: 20px;
  margin: 40px;
  border-radius: 10px;
  width: 60%;
  margin-inline: auto;
    /* display: inline-block; */
}

.errorMessage {
  color: red;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.weather-card {
  align-items: center;
  text-align: center;
  background: transparent;
  backdrop-filter: blur(50px);
  width: 50%;
  border-radius: 10px;
  padding: 30px;
  /* display: inline-block; */
    margin-inline: auto;
}

.weather-card__country {
  background: #eb6e4c;
  padding: 2px 3px;
  border-radius: 10px;
}

.weather-card__header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.weather-card__temp {
  font-size: 42px;
  padding: 10px;
}

.weather-card__description {
  text-transform: uppercase;
}

.weather-card__info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  padding: 20px;
}

.weather-card__detail {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
}

.weather-card__detail span {
  text-align: right;
}
